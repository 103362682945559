import React, { useEffect, useState } from 'react';
import BackgroundImage from 'gatsby-background-image';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';

import { useLightboxState } from '@context/LightboxProvider';

import LinkWithIcon from '@components/LinkWithIcon';
import Icon from '@components/Icon';
import { createPortal } from 'react-dom';

const Lightbox = () => {
    const {
        lightboxOpen,
        setLightboxOpen,
        activeLightboxImage,
        setActiveLightboxImage,
        lightboxImages,
        setLightboxImages,
        lightboxVideo,
        setLightboxVideo
    } = useLightboxState();

    const handleLightboxClose = () => {
        setLightboxOpen(false);

        setTimeout(() => {
            setLightboxImages([]);
            setLightboxVideo(false)
        }, 250);
    }

    return (
        <div className={`grid grid-cols-14 w-full h-full p-10 pb-20 xl:p-20 fixed top-0 left-0 bg-black text-center text-white transition-all ${lightboxOpen
            ? 'opacity-100 visible pointer-events-auto'
            : 'opacity-0 invisible pointer-events-none'
            } z-50`}>

            {lightboxImages.length > 0 && (
                <div className="hidden md:flex items-center justify-center cursor-pointer" onClick={() => setActiveLightboxImage(Math.max(0, activeLightboxImage - 1))}>
                    <Icon
                        name="arrowRight"
                        color="mi-light"
                        className={`transform origin-center rotate-180 ${activeLightboxImage === 0 ? 'opacity-20 pointer-events-none' : 'opacity-100'
                            }`}
                    />
                </div>
            )}

            <div className="col-span-full md:col-span-12 md:col-start-2 lightbox__inner flex items-center justify-center mx-auto w-full h-full top-0 left-0 relative">
                {lightboxImages.length > 0 && (
                    lightboxImages.map(({ gatsbyImageData, alt }, i) => {

                        const image = getImage(gatsbyImageData);
                        const backgroundConvert = convertToBgImage(image);

                        return (
                            <div className={`lightbox__image w-full h-full absolute top-0 left-0 transition-all duration-500 ${i === activeLightboxImage ? 'opacity-100 visible pointer-events-auto' : 'opacity-0 invisible pointer-events-none'
                                }`}
                                data-alt={alt}>
                                <BackgroundImage tag="LightboxImage" {...backgroundConvert} preserveStackingContext className="w-full h-full absolute top-0 left-0 bg-no-repeat bg-contain bg-center" objectFit="contain" objectPosition="center" style={{ position: null }} />
                            </div>
                        )
                    })
                )}
                {lightboxVideo && (
                    <div className="w-full pt-9/16 relative">
                        <video className="w-full h-full absolute top-0 left-0 z-20" controls>
                            <source src={lightboxVideo} type="video/mp4" />
                        </video>
                    </div>
                )}
            </div>

            {lightboxImages.length > 0 && (
                <div className="hidden md:flex items-center justify-center cursor-pointer" onClick={() => setActiveLightboxImage(Math.min(lightboxImages.length - 1, activeLightboxImage + 1))}>
                    <Icon
                        name="arrowRight"
                        color="mi-light"
                        className={`transform ${activeLightboxImage === lightboxImages.length - 1 ? 'opacity-20 pointer-events-none' : 'opacity-100'
                            }`} />
                </div>
            )}

            <LinkWithIcon
                icon="cross"
                color="mi-light"
                className="absolute top-10 right-10"
                clickHandler={() => handleLightboxClose()} />


        </div>
    )
}

export default Lightbox;