import React, { createElement } from "react"

import ImageWithText from "@components/ImageWithText"
import ImageHero from "@components/ImageHero"
import FeaturedGame from "@components/FeaturedGame"
import GamesCarousel from "@components/GamesCarousel"
import LatestNews from "@components/LatestNews"
import CareersSpotlight from "@components/CareersSpotlight"
import Projects from "@components/Projects"
import LifeAtStudio from "@components/LifeAtStudio"
import CareersArchive from "@components/CareersArchive"
import PressReleases from "@components/PressReleases"

const Blades = ({ blades = [], game }) => {
  const Components = {
    DatoCmsImageHero: ImageHero,
    DatoCmsImageWithText: ImageWithText,
    DatoCmsFeaturedGame: FeaturedGame,
    DatoCmsGamesCarousel: GamesCarousel,
    DatoCmsLatestNews: LatestNews,
    DatoCmsNdreamsStudioProject: Projects,
    DatoCmsNdreamsStudioLifeAtStudio: LifeAtStudio,
    DatoCmsCareersArchive: CareersArchive,
    DatoCmsPressReleaseBlock: PressReleases,
  }

  return blades.map((blade, index) => {
    if (Components[blade.__typename]) {
      return createElement(Components[blade.__typename], {
        key: index,
        blade: blade,
      })
    }
  })
}

export default Blades
