import * as React from "react"
import { graphql } from "gatsby"

import Blades from "@components/Blades"
import Root from "@hoc/Root"
import Meta from "@hoc/Meta"

// markup
const IndexPage = ({ data }) => {
  const page = data.datoCmsNdreamsStudio
  const seo = data.datoCmsNdreamsStudio.seo

  return (
    <Root headerColour={page?.headerColour}>
      <Meta {...{ seo }} />
      <Blades {...data.datoCmsNdreamsStudio} />
    </Root>
  )
}

export default IndexPage

export const query = graphql`
  query Homepage {
    datoCmsNdreamsStudio {
      id
      seo {
        description
        title
        twitterCard
        image {
          url
        }
      }
      blades {
        ... on DatoCmsImageHero {
          ...ImageHero
        }
        ... on DatoCmsImageWithText {
          ...ImageWithText
        }
        ... on DatoCmsNdreamsStudioProject {
          ...Projects
        }
        ... on DatoCmsPressReleaseBlock {
          ...PressReleases
        }
        ... on DatoCmsNdreamsStudioLifeAtStudio {
          ...LifeAtStudio
        }
        ... on DatoCmsCareersArchive {
          ...CareersArchive
        }
      }
    }
  }
`
