import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import cx from 'classnames'
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { useLightboxState } from '@context/LightboxProvider';
import LinkWithIcon from '@components/LinkWithIcon';
import Icon from '@components/Icon';

import ReactPlayer from 'react-player';

import { A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react';


import 'swiper/css';
import './styles.scss';

const GalleryCard = ({
    onMouseEnter,
    onMouseLeave,
    handleVideoClick,
    gatsbyImageData,
    alt,
    video
}) => {
    const imageData = gatsbyImageData && getImage(gatsbyImageData);

    return (
        <div className='h-full w-full relative bg-aldo'>
            {imageData && <GatsbyImage image={imageData} alt={alt ? alt : 'Carousel Image Alt'} style={{ position: null }} className="w-full h-full" objectFit="cover" objectPosition="center" />}
            {video && (
                <div className="relative h-full w-full flex items-center justify-center">
                    <img src={video.thumbnailUrl} alt={`something`} className="w-full h-full object-cover object-center filter brightness-50" />
                    <LinkWithIcon color="mi-light" clickHandler={() => handleVideoClick(video.mp4Url)} text="" icon="play" className="hidden absolute sm:flex" />
                </div>
            )}
        </div>
    )
}

const LifeAtStudio = ({ blade: { heading, assetsGallery = [], videoAsset, externalVideo, externalVideoAsset, body, gridItems = [], playButtonColour, verticalLabel } }) => {

    const { setLightboxVideo, setLightboxOpen } = useLightboxState();
    const [active, setActive] = useState(true);

    const handleVideoClick = (trailer) => {
        setActive(false)
        setLightboxVideo(trailer)
        setLightboxOpen(true)
    }

    console.log(externalVideo);

    return (
        <div className="pt-28 pb-8 md:py-32 bg-bare overflow-x-hidden relative">
            <div className="container mx-auto relative z-20">
                <div className="grid grid-cols-1 md:grid-cols-14 px-10">
                    <div className="hidden xl:block relative">
                        <div className={cx('gallery-carousel__label pointer-events-none whitespace-nowrap uppercase text-center font-medium vert-label')}>Life at the studio</div>
                    </div>
                    <div className="col-span-full xl:col-span-12">
                        <div className="">
                            <h2 className={`text-secondary text-4xl sm:text-5xl md:text-6xl font-extrabold uppercase col-span-full mb-8`}><ReactMarkdown children={heading} /></h2>
                            <ReactMarkdown children={body} className="wysiwyg" />
                        </div>
                        {externalVideo ? (
                            <div className="react-player-wrapper mt-10">
                                <ReactPlayer className="react-player" url={externalVideoAsset.url} controls width='100%' height='100%' />
                            </div>
                        ) : (
                            <div className="player-wrapper mt-10">
                                <video controls src={videoAsset.video.mp4Url} poster={videoAsset.video.thumbnailUrl} />
                            </div>
                        )}

                        {/* <div className={`gallery-carousel col-span-full lg:col-span-10 xl:col-span-13 mt-10 mb-0 md:transition md:duration-500 overflow-visible lg:grid w-full`}>
                            {assetsGallery && (
                                <div className="swipe-container">
                                    <Swiper
                                        modules={[A11y, Autoplay]}
                                        slidesPerView={1.5}
                                        spaceBetween={20}
                                        className="overflow-visible cursor-pointer"
                                        autoplay={{ delay: 5000 }}
                                        loop={true}
                                        grabCursor={true}
                                        breakpoints={{
                                            320: {
                                                slidesPerView: 1,
                                            },
                                            1024: {
                                                slidesPerView: 1.5,
                                            },
                                            1280: {
                                                slidersPerView: 2,
                                            },
                                            1536: {
                                                slidersPerView: 2.5
                                            }
                                        }}>
                                        {assetsGallery.map((galleryItem, i) => (
                                            <SwiperSlide className="!h-[400px] lg:!h-[550px] xl:!h-[600px] 2xl:!h-[650px]" key={i}>
                                                <GalleryCard {...{
                                                    handleVideoClick,
                                                    ...galleryItem
                                                }} />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            )}
                        </div> */}

                        {gridItems && (
                            <div className='col-span-full xl:col-span-12 grid grid-cols-1 md:grid-cols-6 xl:grid-cols-12 gap-4 lg:gap-6 mt-12'>
                                {gridItems.map((item, index) => {
                                    const itemImage = getImage(item.image.gatsbyImageData);

                                    return (
                                        <div className="col-span-3" key={index}>
                                            <div className="overflow-hidden flex items-center justify-center">
                                                <GatsbyImage image={itemImage} alt={item.image.alt ? item.image.alt : 'Grid Item Image'} objectFit="cover" objectPosition="center" className="mx-auto" style={{ position: null }} />
                                            </div>
                                            <h3 className="text-secondary font-extrabold text-center uppercase my-6 text-2xl md:text-3xl lg:text-4xl px-2">{item.heading}</h3>
                                            <ReactMarkdown children={item.body} className="wysiwyg text-center px-2" />
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>

                </div>
            </div>
            <div className="bg-white absolute -top-28 right-56 w-60 h-3/4 rotate-[40deg] hidden xl:block bg-gradient-to-t from-bare to-white" />
        </div >
    )
}

export default LifeAtStudio;